import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'app/services/account/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'app/services/account/user.service';
import { Credentials } from 'app/models/credentials.interface';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { TranslatePipe } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { DomSanitizer } from '@angular/platform-browser';
import { PasswordValidator } from 'app/services/helpers/validators/password.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TranslatePipe],
  animations: fuseAnimations
})

export class ResetPasswordComponent implements OnInit {

  onSiteLogo: string;
  onPremisePrefix: string;
  onSiteBackground: string;
  imageName: string = "/assets/images/backgrounds/password.jpg";
  public router: Router;
  public name: AbstractControl;
  public email: AbstractControl;
  public password: AbstractControl;
  public confirmPassword: AbstractControl;
  public pageParams: any;
  public testResult: any;

  public form: FormGroup;
  public resetPasswordFormErrors: any;

  public linkInvalid: boolean;
  public domainLang: string = "TR";
  public domainURL: string = "";
  public baseUrl: string = "";
  public siteUrl: string = "";
  public linkCompleted: boolean = false;


  langDic = [
    { "key": "KM", "value": "ER", "language": "EN" },
    { "key": "Şifre Oluşturma", "value": "Password Reset", "language": "EN" },
    { "key": "Bu şifre oluşturma bağlantısı artık geçerli değildir.", "value": "This link is no longer valid", "language": "EN" },
    { "key": "E-posta adresiniz gereklidir.", "value": "E-mail is required", "language": "EN" },
    { "key": "Şifre giriniz", "value": "Please enter your password", "language": "EN" },
    { "key": "Şifre doğrulama gereklidir", "value": "Please verify your password", "language": "EN" },
    { "key": "En az 1 adet rakam kullanmalısınız", "value": "At least 1 number required in your password", "language": "EN" },
    { "key": "En az 1 adet büyük harf gereklidir", "value": "At least 1 Uppercase letter required in your password", "language": "EN" },
    { "key": "En az 1 adet küçük harf gereklidir", "value": "At least 1 Lowercase letter required in your password", "language": "EN" },
    { "key": "l 1 o O 0 i L gibi benzer karakter kullanılmamalıdır", "value": "You have to void following letters 'l 1 o O 0 i L'", "language": "EN" },
    { "key": "-!$%^&* gibi en az", "value": "You have to use at lease", "language": "EN" },
    { "key": "adet özel karakterler gereklidir", "value": "special letters (like: -!$%^&*)", "language": "EN" },
    { "key": "Şifreniz en az", "value": "Your password should be at least", "language": "EN" },
    { "key": "karakter olmalıdır", "value": "letters", "language": "EN" },
    { "key": "İki şifre birbiri ile aynı olmalı", "value": "Your new password doesnt match", "language": "EN" },
    { "key": "ŞİFREMİ OLUŞTUR", "value": "CREATE MY PASSWORD", "language": "EN" },
    { "key": "Şifre oluşturma işleminiz tamamlanmıştır.", "value": "Your password has been successfully updated", "language": "EN" },
    { "key": "Giriş sayfasına geri dön", "value": "Return to login page", "language": "EN" },
    { "key": "Şifreniz güncellendi, lütfen tekrar giriş yapın.", "value": "Your password has been updated. Please re-login.", "language": "EN" }
  ]



  constructor(private fuseConfig: FuseConfigService,
    private _translatePipe: TranslatePipe,
    private _sanitizer: DomSanitizer,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private route: ActivatedRoute, router: Router,
    private userService: UserService,
    private loginService: LoginService,
    private fb: FormBuilder) {
    this.fuseConfig.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.resetPasswordFormErrors = {
      password: {}
    };

    this.route.params.subscribe((params) => {
      this.pageParams = params
      this.loginService.passwordLevel(this.pageParams.id).pipe()
        .subscribe(
          inf => {
            let pv = PasswordValidator.low;

            switch (inf.level) {
              case "insane":
                pv = PasswordValidator.insane;
                break;
              case "medium":
                pv = PasswordValidator.medium;
                break;
              case "strong":
                pv = PasswordValidator.strong;
                break;
                case null:
                  case "":
                this.linkInvalid = true;
                break;
            }


            this.form = fb.group({
              password: ['', Validators.compose([Validators.required, pv])],
              passwordConfirm: ['', Validators.required]
            }, { validator: matchingPasswords('password', 'passwordConfirm') });

            this.email = this.form.controls['email'];
            this.password = this.form.controls['password'];
            this.confirmPassword = this.form.controls['passwordConfirm'];

            this.form.valueChanges.subscribe(() => {
              this.onResetPasswordFormValuesChanged();
            });

            this.testResult = inf.level;
          })
    });

    this.form = fb.group({
      password: ['', Validators.required],
      passwordConfirm: ['', Validators.required]
    }, { validator: matchingPasswords('password', 'passwordConfirm') });
    this.router = router;
    this.domainURL = userService.domainUrl;
    this.baseUrl = userService.baseUrl;
    this.domainLang = "TR";

  }
  translate = function (value) {

    var returnval = value;

    if (value != null && this.domainLang != "TR") {
      for (var i = 0; i < this.langDic.length; i++) {
        if (this.langDic[i].key == value) {
          returnval = this.langDic[i].value;
          break;
        }
      }
    }

    return returnval;
  }

  onResetPasswordFormValuesChanged() {
    for (const field in this.resetPasswordFormErrors) {
      if (!this.resetPasswordFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.resetPasswordFormErrors[field] = {};

      // Get the control
      const control = this.form.get(field);

      if (control && control.dirty && !control.valid) {
        this.resetPasswordFormErrors[field] = control.errors;
      }
    }
  }

  public onSubmit(values: any): void {
    if (this.form.valid) {
      //alert("test");
      this.userService.updatepasswordrequest(values.password, this.pageParams.id)
        .pipe()
        .subscribe(
          result => {
            if (result) {
              alert(this.translate('Şifreniz güncellendi, lütfen tekrar giriş yapın.'));

              this.router.navigate(['/pages/login']);
              // this.toastr.success(, 'OK', {
              //     timeOut: 10000
              // });
              this.linkCompleted = true;
            } else {

            }

          });
    }
  }

  ngAfterViewInit() {
  }
  ngOnInit() {
    this.form = this.fb.group({
      password: ['', Validators.required],
      passwordConfirm: ['', Validators.required]
    });

    this.form.valueChanges.subscribe(() => {
      this.onForgotPasswordFormValuesChanged();
    });
  }

  onForgotPasswordFormValuesChanged() {
    for (const field in this.resetPasswordFormErrors) {
      if (!this.resetPasswordFormErrors.hasOwnProperty(field)) {
        continue;
      }

      // Clear previous errors
      this.resetPasswordFormErrors[field] = {};

      // Get the control
      const control = this.form.get(field);

      if (control && control.dirty && !control.valid) {
        this.resetPasswordFormErrors[field] = control.errors;
      }
    }
  }

}


export function emailValidator(control: FormControl): { [key: string]: any } {
  var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
  return (group: FormGroup) => {
    let password = group.controls[passwordKey];
    let passwordConfirmation = group.controls[passwordConfirmationKey];
    if (password.value !== passwordConfirmation.value) {
      return passwordConfirmation.setErrors({ mismatchedPasswords: true })
    }
  }
}


export function insanePass(control: FormControl): { [key: string]: any } {
  let hasNumber = /\d/.test(control.value);
  let hasUpper = /[A-Z]/.test(control.value);
  let hasLower = /[a-z]/.test(control.value);
  let hasSimilar = /[l1oO0iL]/.test(control.value);
  let hasSpecial = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/].{1,}/.test(control.value);
  // console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
  const valid = hasNumber && hasUpper && hasLower && hasSpecial && (control.value.length > 19);
  if (!valid) {
    // return what´s not valid
    return {
      hasNumber: hasNumber,
      hasUpper: hasUpper,
      hasLower: hasLower,
      hasSimilar: false,
      hasSpecial: hasSpecial,
      hasLength: control.value.length > 19,
      hasError: !valid,
      minLength: 20,
      minSpecialChar: 2
    };
  }
  return null;
} 