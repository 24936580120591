import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/helpers/authguard';
import { LoginComponent } from './main/pages/login/login-2.component';
import { ForgotPasswordComponent } from './main/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './main/reset-password/reset-password.component';

const routes: Routes = [
    {
        path: 'pages/login',
        component: LoginComponent
    },
    {
        path: 'auth/forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'reset-password/:id',
        component: ResetPasswordComponent
    },
    {
        path: 'profile',
        loadChildren: () => import('app/main/pages/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'profile/:rowId',
        loadChildren: () => import('app/main/pages/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard',
        loadChildren: () => import('app/main/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reports',
        loadChildren: () => import('app/main/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'psycologists',
        loadChildren: () => import('app/main/contacts/contacts.module').then(m => m.ContactsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'clients',
        loadChildren: () => import('app/main/clients/clients.module').then(m => m.ClientsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'incomingcalls',
        loadChildren: () => import('app/main/incomingcalls/incomingcalls.module').then(m => m.IncomingCallsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'shifts',
        loadChildren: () => import('app/main/shifts/shifts.module').then(m => m.ShiftsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'sample',
        loadChildren: () => import('app/main/sample/sample.module').then(m => m.SampleModule),
        canActivate: [AuthGuard]
    },
    {
        path: '', 
        pathMatch: 'full', 
        redirectTo: '/pages/login'
    },
    {
        path: '**',
        loadChildren: () => import('app/main/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    }
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
})
export class AppRoutingModule { }



