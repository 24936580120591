import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Raporlar',
        type     : 'group',
        children : [
            {
                id       : 'dashboard',
                title    : 'Dashboard',
                translate: 'Dashboard',
                type     : 'item',
                icon     : 'dashboard',
                url      : '/dashboard'
            },
            {
                id       : 'cagrilar',
                title    : 'Görüşmeler',
                translate: 'Görüşmeler',
                type     : 'item',
                icon     : 'phone',
                url      : '/incomingcalls/conversations'
            }
        ]
    },
    {
        id       : 'records',
        title    : 'Kayıtlar',
        type     : 'group',
        children : [
            {
                id       : 'psikologlar',
                title    : 'Psikologlar',
                translate: 'Psikologlar',
                type     : 'item',
                icon     : 'people',
                url      : '/psycologists'
            },
            {
                id       : 'musteriler',
                title    : 'Firmalar',
                translate: 'Firmalar',
                type     : 'item',
                icon     : 'business_center',
                url      : '/clients/companies'
            }
        ]
    },
    // {
    //     id       : 'planlama',
    //     title    : 'Planlama',
    //     type     : 'group',
    //     children : [
    //         {
    //             id       : 'Shiftler',
    //             title    : 'Shiftler',
    //             translate: 'Shiftler',
    //             type     : 'item',
    //             icon     : 'access_time',
    //             url      : '/shifts'
    //         }
    //     ]
    // }
];


export const navigation2: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Raporlar',
        type     : 'group',
        children : [
            {
                id       : 'dashboard',
                title    : 'Dashboard',
                translate: 'Dashboard',
                type     : 'item',
                icon     : 'dashboard',
                url      : '/dashboard'
            },
            {
                id       : 'aramalar',
                title    : 'Görüşmeler',
                translate: 'Görüşmeler',
                type     : 'item',
                icon     : 'phone',
                url      : '/incomingcalls/conversations'
            }
        ]
    } 
];
