import { Component, ElementRef, Input, OnChanges, ViewChild, ViewEncapsulation, HostListener, AfterContentInit } from '@angular/core';
import * as d3 from 'd3';
import { DataModel, ShiftModel } from '../../../models/data.model';

@Component({
  selector: 'app-shift-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './shift-chart.component.html',
  styleUrls: ['./shift-chart.component.scss']
})
export class ShiftChartComponent implements OnChanges {
  @ViewChild('shiftChart')
  private chartContainer: ElementRef;

  @ViewChild('shiftTooltip')
  private shiftTooltip: ElementRef;

  @Input()
  data: ShiftModel[];

  
  @Input()
  colorschema: [];

  margin = { top: 30, right: 0, bottom: 20, left: 30 };

  constructor() { }

  ngOnChanges(): void {
    if (!this.data) { return; }
    if (!this.chartContainer) {
      setTimeout(() => {
        if (this.chartContainer) {
          var element = this.chartContainer.nativeElement;
          if (element.offsetWidth <= 0) { return; }
          this.createChart();
        }
      }, 1000);
      return;
    }

    var element = this.chartContainer.nativeElement;
    if (element.offsetWidth <= 0) { return; }
    this.createChart();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (!this.data) { return; }
    if (!this.chartContainer) { return; }
    var element = this.chartContainer.nativeElement;
    if (element.offsetWidth <= 0) { return; }
    this.createChart();
  }
  
  
  private createChart(): void {
    d3.select('svg').remove();
    var date = new Date;
    var day = date.getDay();
    var hour = date.getHours(); 


    const days = ["Pt", "Sa", "Ça", "Pe", "Cu", "Cm", "Pz"];
    const times = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
    //const colors = ["#eeeeee", "#33d6ff", "#ffbb33", "#88cc00", "#a64dff", "#225ea8", "#253494", "#081d58"]; // alternatively colorbrewer.YlGnBu[9]
    const colors = [];
    for (let x = 0; x < this.colorschema.length; x++) {
      colors.push(this.colorschema[x]);
    }

    const element = this.chartContainer.nativeElement;
    const tooltip = this.shiftTooltip.nativeElement;

    const data = this.data;


    const contentWidth = element.offsetWidth - this.margin.left - this.margin.right;
    const contentHeight = contentWidth / 1.5;//element.offsetHeight - this.margin.top - this.margin.bottom;
    var gridSize = Math.floor(contentWidth / 24);

    var cxMarg = contentWidth * 0.020;
    var cyMarg = contentWidth * 0.019;
    var rMarg = contentWidth * 0.019;

    var tooltipDiv = d3.select(tooltip).attr("class", "subtooltip") .style("opacity", 0);


    const svg = d3.select(element).append('svg')
      .attr('width', element.offsetWidth)
      .attr('height', contentHeight)
      .append("g")
      .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")").on('mouseout', (d, i) => {
        tooltipDiv.transition().duration(750).style("opacity", 0)
        .style('left','-200px')
     });
      


    const g = svg.append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    // var cards = svg.selectAll(".hour")
    //   .data(data, function (d: ShiftModel) { return d.day + ':' + d.hour; });

    var dayLabels = svg.selectAll(".dayLabel")
      .data(days)
      .enter().append("text")
      .text(function (d) { return d; })
      .attr("x", 0)
      .attr("y", function (d, i) { return i * gridSize; })
      .style("text-anchor", "end")
      .attr("transform", "translate(-6," + gridSize / 2 + ")")
      .attr("class", function (d, i) { return ((i >= 0 && i <= 4) ? "dayLabel mono axis axis-workweek" : "dayLabel mono axis"); });

    var timeLabels = svg.selectAll(".timeLabel")
      .data(times)
      .enter()
      .append("text")
      .text(function (d) { return d; })
      .attr("x", function (d, i) { return i * gridSize; })
      .attr("y", 0)
      .style("text-anchor", "middle")
      .attr("transform", "translate(" + gridSize / 2 + ", -6)")
      .attr("class", function (d, i) { return ((i >= 7 && i <= 16) ? "timeLabel mono axis axis-worktime" : "timeLabel mono axis"); });



    var cards = svg.selectAll(".hour")
      .data(data, function (d: any) { return d.day + ':' + d.hour; });
  
    var timeOutFlag;

    var _this = this;

    for (let c = 0; c < 4; c++) {
      
      var rad = 1;
      switch (c) {
        case 1:
          rad = 1.4;
          break;
        case 2:
          rad = 2;
          break;
        case 3:
          rad = 4;
          break; 
      }
      cards.enter().append("circle")
        .attr("cx", function (d: any) { return ((d.hour - 1) * gridSize) + cxMarg; })
        .attr("cy", function (d: any) { return ((d.day - 1) * gridSize) + cyMarg; })
        .on('mousemove', (d) => {
          var textX = Number(d3.select(d3.event.currentTarget).attr("cx"));
          var textY = Number(d3.select(d3.event.currentTarget).attr("cy"));
       //   console.log(d3.select(d3.event.currentTarget).attr("cy"))
          var offsetX =-220;
          var offsetY =50;

          if(textY<70){
            offsetY =150;
          } 
          if(textX<200){
            offsetX =100;
          } 

          tooltipDiv.html(d.tooltip[c]).transition().duration(200)
          .style('top',(Number(d3.select(d3.event.currentTarget).attr("cy")) + offsetY) + 'px')
          .style('left', (Number(d3.select(d3.event.currentTarget).attr("cx")) +offsetX) + 'px')
          
          .style("opacity", 1);
         
         
          // tooltipDiv.transition()
          //   .duration(200)
          //   .style("display", "block");
          //   tooltipDiv.html(d.tooltip[c])
            // .style("left", (Number(d3.select(d3.event.currentTarget).attr("cx")) - 55) + "px")
            // .style("top",  + "px");
          d3.select(d3.event.currentTarget).style("fill", "red")
        })
        .on('mouseout', (d, i) => {
          // tooltipDiv.transition()
          //   .style("opacity", 0);
          d3.select(d3.event.currentTarget).style("fill", function (d: any) {
            return d.psycAreaIds.length > 0 ? colors[d.psycAreaIds[c]] : colors[0];
          })
        })
        .attr("r", 2)
        .attr("class",(d, i) => {  
           
          return (d.day+7)%7==day&&d.hour==hour+1 ? "hour REDbordered":"hour bordered";
        })
        .attr("width", gridSize)
        .attr("height", gridSize)
        .style("fill", colors[0])
        .style("fill", function (d: any) {
          //   return d.psycAreaIds.length > c ? d3.rgb(colors[d.psycAreaIds[c]]).darker(d.personCount[c]/2).toString() : d3.rgb(colors[0]).toString();
          return d.psycAreaIds.length > c ? colors[d.psycAreaIds[c]] : colors[0];
        }) 
        .transition().duration(1000)
        .attr("fill-opacity", function (d: any) {
          return d.personCount[c]*0.20;
        })
        .attr("r",  function (d: any) {
          return d.psycAreaIds.length > c ? rMarg /rad : 0; //VBColorToHEX(colorScale(d.psycAreaIds))
        })

    }
   
    



    //.on("", handleMouseOut);; 
















    // cards.enter().append("circle")
    //   .attr("cx", function (d: any) { return ((d.hour - 1) * gridSize) + cxMarg; })
    //   .attr("cy", function (d: any) { return ((d.day - 1) * gridSize) + cyMarg; })
    //   .attr("r", 2)
    //   .attr("class", "hour bordered")
    //   .attr("width", gridSize)
    //   .attr("height", gridSize)
    //   .style("fill", colors[0])
    //   .style("fill", function (d: any) {
    //     //  console.log(colorScale(d.psycAreaIds));  
    //     return d.psycAreaIds.length > 1 ? colors[d.psycAreaIds[1]] : null; //VBColorToHEX(colorScale(d.psycAreaIds))
    //   })
    //   .transition().duration(1000)
    //   .attr("r", function (d: any) {
    //     return d.psycAreaIds.length > 1 ? rMarg / 1.4 : 0; //VBColorToHEX(colorScale(d.psycAreaIds))
    //   });

    // cards.enter().append("circle")
    //   .attr("cx", function (d: any) { return ((d.hour - 1) * gridSize) + cxMarg; })
    //   .attr("cy", function (d: any) { return ((d.day - 1) * gridSize) + cyMarg; })
    //   .attr("r", 2)
    //   .attr("class", "hour bordered")
    //   .attr("width", gridSize)
    //   .attr("height", gridSize)
    //   .style("fill", colors[0])
    //   .style("fill", function (d: any) {
    //     //  console.log(colorScale(d.psycAreaIds));  
    //     return d.psycAreaIds.length > 2 ? colors[d.psycAreaIds[2]] : null; //VBColorToHEX(colorScale(d.psycAreaIds))
    //   })
    //   .transition().duration(1000)
    //   .attr("r", function (d: any) {
    //     return d.psycAreaIds.length > 2 ? rMarg / 2 : 0; //VBColorToHEX(colorScale(d.psycAreaIds))
    //   });

    // cards.enter().append("circle")
    //   .attr("cx", function (d: any) { return ((d.hour - 1) * gridSize) + cxMarg; })
    //   .attr("cy", function (d: any) { return ((d.day - 1) * gridSize) + cyMarg; })
    //   .attr("r", 2)
    //   .attr("class", "hour bordered")
    //   .attr("width", gridSize)
    //   .attr("height", gridSize)
    //   .style("fill", colors[0])
    //   .style("fill", function (d: any) {
    //     //  console.log(colorScale(d.psycAreaIds));  
    //     return d.psycAreaIds.length > 3 ? colors[d.psycAreaIds[3]] : null; //VBColorToHEX(colorScale(d.psycAreaIds))
    //   })
    //   .transition().duration(1000)
    //   .attr("r", function (d: any) {
    //     return d.psycAreaIds.length > 3 ? rMarg / 4 : 0; //VBColorToHEX(colorScale(d.psycAreaIds))
    //   });
    cards.select("title").text(function (d: any) { return d.psycAreaIds.length > 0 ? d.psycAreaIds[0] : ""; });

    cards.exit().remove();

  }
  // Create Event Handlers for mouse

}
