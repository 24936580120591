import { NgModule } from '@angular/core'; 
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FuseSharedModule } from '@fuse/shared.module';
import { RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './reset-password.component';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

@NgModule({
    declarations: [ 
        ResetPasswordComponent
    ],
    imports     : [
        RouterModule,
        TranslateModule,
        MatSnackBarModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        FuseSharedModule
    ],
    providers :[
        TranslatePipe
    ],
})
export class ResetPasswordModule
{

}
