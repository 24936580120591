import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, observable, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseService } from '../helpers/base.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class UserService extends BaseService {

  //--- herkes icin dolabilir
  private userInfoDetail_ = new BehaviorSubject<any>({} as any);
  userInfoDetail = this.userInfoDetail_.asObservable();
 
  //---
  private navMenu_ = new BehaviorSubject<any>({} as any);
  navMenu = this.navMenu_.asObservable();

  private userInfo_ = new BehaviorSubject<any>({} as any);
  userInfo = this.userInfo_.asObservable();
 


  private _unsubscribeAll: Subject<any>;
  
  constructor(private http: HttpClient,private cookieService: CookieService,private _router: Router) {
    super(http, cookieService,_router);
    
    // Set the private defaults
    this._unsubscribeAll = new Subject();
     
    if (this.cookieService.get('user_info')!="") {
      var uInf = JSON.parse(this.cookieService.get('user_info'));
      this.userInfo_.next(uInf);
      
      if(this._router.url === '/elogin'||this._router.url === '/pages/login')
            this.navMenu_.next([]);
       else {
          // let subs = this.accountMenu().subscribe((value) => {
          //   subs.unsubscribe();
          // }); 
      }
    }
  } 
  updatepasswordrequest(updateInfo, key) {
    var data = {
      password: updateInfo,
      resetkey: key
    };
    return this.basePost(this.baseUrl + '/account/resetpassword', data)
    .pipe(map(res => {
      return res;
    })); 
  }
  isSessionValid() {
    return this.basePost(this.baseUrl + '/account/isSessionValid', {})
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((result) => {
                if(result)
                  return true
                else 
                  return false;
              });
              // .pipe(map(res => {
              //   return res;
              // }));
  }
  userInfoSubscription() {
    return this.basePost(this.baseUrl + '/account/info', {})
      .pipe(map(res => {
        let j = JSON.stringify(res);
        //console.log(res);
      
        this.setBaseItem('user_info', j);
        // this.setBaseItem('is_a', res["isA"]);
        // this.setBaseItem('is_s', res["isS"]);
        this.userInfo_.next(res as any);

        return res as any;
      }));
  }
  resetUser(){
    this.clearCookie();
    this.changeLoggedIn(false);
    this.navMenu_.next([]);
    this.userInfo_.next({} as any);
  }
  logout() { 
    return this.basePost(this.baseUrl + '/account/logout', {})
      .pipe(map(res => {
        return res;
      }));
  }
 
  userCheck() {
    return this.basePost(this.baseUrl + '/user/check',null)
      .pipe(map(res => {
        return res;
      }));
  }
  

  isLoggedIn() {
    var isToken = this.getBaseItem('auth_token');
    if(isToken!=""&&isToken!=null){
     // return true;
     return this.basePost(this.baseUrl + '/account/isSessionValid', {})
                             .pipe(map(res => {
                                 return res;
                              }))
                             // .toPromise();
                        // .pipe(takeUntil(this._unsubscribeAll))
                        // .subscribe((result) => {
                        //                             if(result)
                        //                               return true
                        //                             else 
                        //                               return false;
                        //                         });

      // return this.isSessionValid();
    }
    return new BehaviorSubject<any>(false) ;
    // return this.loggedIn;
  }
    
}