import { Component, OnInit ,ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations'; 
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'app/services/account/login.service';  
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'app/services/account/user.service';
import { Credentials } from 'app/models/credentials.interface';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { TranslatePipe } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
 
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [TranslatePipe],
    animations: fuseAnimations
})

export class ForgotPasswordComponent implements OnInit {
   
    forgotPasswordForm: FormGroup;
    forgotPasswordFormErrors: any;
    onPremisePrefix:string;
    onSiteLogo:string;
    onSiteBackground:string; 
    imageName:string = "/assets/images/backgrounds/password.jpg";
    errors: string;
    isRequesting: boolean;
    submitted: boolean = false;
  

    constructor(private fuseConfig: FuseConfigService,
        private _translatePipe: TranslatePipe, 
        private _sanitizer: DomSanitizer,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
         private formBuilder: FormBuilder, private activatedRoute: ActivatedRoute, private router: Router, public snackBar: MatSnackBar, private loginService: LoginService, private userService: UserService,private _fuseSplashScreenService: FuseSplashScreenService) {
        this.fuseConfig.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        }; 
    
          this.forgotPasswordFormErrors = {
            email: {}
          };
         
    }
    getBackgroundrUrl() {
      // snip snip -> fetch the url from somewhere
      const profilePicUrl = "/assets/images/backgrounds/password.jpg";
      const style = `url(${profilePicUrl})`;
      return this._sanitizer.bypassSecurityTrustStyle(style);
    }

    ngOnInit() {
          this.forgotPasswordForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
          });
      
          this.forgotPasswordForm.valueChanges.subscribe(() => {
            this.onForgotPasswordFormValuesChanged();
          });
    }


    onForgotPasswordFormValuesChanged() {
        for (const field in this.forgotPasswordFormErrors) {
          if (!this.forgotPasswordFormErrors.hasOwnProperty(field)) {
            continue;
          }
    
          // Clear previous errors
          this.forgotPasswordFormErrors[field] = {};
    
          // Get the control
          const control = this.forgotPasswordForm.get(field);
    
          if (control && control.dirty && !control.valid) {
            this.forgotPasswordFormErrors[field] = control.errors;
          }
        }
      }
        
  sendResetLink({ value, valid }: { value: string, valid: boolean }) {
    this.submitted = true;
    this.isRequesting = true;
    this.errors = '';
    if (valid) {
        this.loginService.resetpasswordrequest(value)
            .subscribe(
                result => {
                  this.snackBar.open("Lütfen e-postanızı kontrol edin.", 'OK', {
                    verticalPosition: 'top',
                    duration: 10000
                  });
                  this.forgotPasswordForm = this.formBuilder.group({
                    email: ['', [Validators.required, Validators.email]]
                  });
            
                },
                error => this.errors = error);
    }
}
}
