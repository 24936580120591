import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { UserService } from '../account/user.service';
import { Observable } from 'rxjs';
@Injectable()
export class AuthGuard implements CanActivate {
  thisuser: any;

  constructor(
    private _userService: UserService,
    private myRoute: Router) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return new Promise(resolve => {

      this._userService.userInfo.subscribe((value) => {
        this.thisuser = value;
      });

      this._userService.isLoggedIn().subscribe((result) => {
        if (result.isSuccess) {
          return resolve(true);
        } else {
          // console.log("login redirected", state.url);
          this.myRoute.navigate(["/pages/login"], { queryParams: { returnUrl: state.url } });
          return resolve(false);
        }
      }, (error) => {
        // console.log("login redirected error", state.url);
        this.myRoute.navigate(["/pages/login"], { queryParams: { returnUrl: state.url } });
        return resolve(false);
      });

    });
  }
}