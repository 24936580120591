<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="start" [style.background-image]="'url(' + imageName + ')'" [style.background-size]="'cover'" >

    <div id="login-intro" fxFlex fxHide fxShow.gt-xs> 
      <div class="logo" [@animate]="{value:'*',params:{scale:'0.1'}}" >
            <img src="assets/images/logos/sento-logo.png">
            <div style="text-align: center;" class="secondary-text mb-8 ml-16" style="font-size: 12px;" >yeni nesil kurumsal psikolojik danışmanlık</div>
        </div>  
    </div>
    
        <div id="password-form-wrapper" fusePerfectScrollbar *fuseIfOnDom
        [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}" fxLayout="column">

              <div id="login-form">
                <div class="logo" fxHide.gt-xs>
                    <img src="assets/images/logos/sento-logo.png">
                </div>
                
    
                <div class="title"  style="text-align: center;">Şifre Resetleme</div>
    
                <form name="forgoPasswordForm" [formGroup]="forgotPasswordForm" (ngSubmit)="sendResetLink(f)"  #f="ngForm" novalidate>
    
                    <mat-form-field>
                        <input matInput placeholder="E-posta" formControlName="email">
                        <mat-error *ngIf="forgotPasswordFormErrors.email.required">
                            E-posta adresiniz gereklidir.
                        </mat-error>
                        <mat-error *ngIf="!forgotPasswordFormErrors.email.required && forgotPasswordFormErrors.email.email">
                            Lütfen geçerli bir e-posta adresi girin.
                        </mat-error>
                    </mat-form-field>
                    <button mat-raised-button class="submit-button" color="accent"  [disabled]="forgotPasswordForm.invalid">
                        Şifre Yenileme Bağlantısını Gönder
                    </button>
    
                </form>
    
                <div class="login" fxLayout="row" fxLayoutAlign="center center">
                    <a class="link" [routerLink]="'/pages/login'">Giriş sayfasına geri dön</a>
                </div>
    
            </div>
        </div>
    </div>