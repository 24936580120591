import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { sessionStore } from '../../app.module';
import { BaseService } from '../helpers/base.service';
import { CookieService } from 'ngx-cookie-service';


@Injectable()
export class LoginService extends BaseService {

  // Observable navItem source
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this._authNavStatusSource.asObservable();

  constructor(private http: HttpClient, private _router: Router, private userService: UserService, cookieService: CookieService) {
    super(http, cookieService, _router);
  }

  preLogin(userEmail, password) {
    return this.basePost(this.baseUrl + '/api/auth/login', { userEmail, password })
      .pipe(map(res => {
        if (res != null) {
          if (res.message != "error") {
            if (res.isExecutive != undefined) {
              return res;
            } else {
              this.setBaseItem('auth_token', res.authToken);
              this.setBearer(res.authToken);
              
                this.changeLoggedIn(true);
              this._authNavStatusSource.next(true);
              return true;
            }
          }
          return false;

        }
        return false;
      }));
  }

  wallpaper(){
    return this.basePost(this.baseUrl + '/api/wallpaper',null)
      .pipe(map(res => {
        if (res != null) {
          return res;
        }
        return null;
      }));
  }

  resetpasswordrequest(userEmail) {

    return this.basePost(this.baseUrl + '/account/resetpasswordrequest', userEmail)
      .pipe(map(res => {
        return true;
      }));
  }

  passwordLevel(key) {

    var data = { password: "", resetkey: key };
    return this.basePost(this.baseUrl + '/system/passwordLevel', data)
      .pipe(map(res => {
        return res;
      }));
  }


  updatepasswordrequest(updateInfo, key) {

    var data = { password: updateInfo.password, resetkey: key };
    return this.basePost(this.baseUrl + '/account/resetpassword', data)
      .pipe(map(res => {
        return true;
      }));
  }

  logout(isRedirect) {

    let subs = this.userService.logout().subscribe(() => {
      this.userService.resetUser();
      subs.unsubscribe();
      this._router.navigate(['/pages/login']);
    });
  }
}