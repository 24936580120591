<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="start" [style.background-image]="'url(' + imageName + ')'" [style.background-size]="'cover'" >

    <div id="login-intro" fxFlex fxHide fxShow.gt-xs> 
      <div class="logo" [@animate]="{value:'*',params:{scale:'0.1'}}" >
            <img src="assets/images/logos/sento-logo.png">
            <div style="text-align: center;" class="secondary-text mb-8 ml-16" style="font-size: 12px;" >{{background.message}}</div>
        </div>  
    </div>

    <div id="login-form-wrapper" fusePerfectScrollbar
         [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}" >

        <div id="login-form">
 
            <div class="logo" fxHide.gt-xs>
                <img src="assets/images/logos/sento-logo.png">
            </div>


            <div class="title" style="text-align: center;">Giriş Yapın</div>

            <form name="loginForm" [formGroup]="loginForm" novalidate  (ngSubmit)="preLogin(f)" #f="ngForm">

                <mat-form-field appearance="outline">
                    <mat-label>E-posta</mat-label>
                    <input matInput formControlName="email">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="loginForm.get('email').hasError('required')">
                        E-posta adresinizi girin
                    </mat-error>
                    <mat-error
                        *ngIf="!loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')">
                        Geçerli bir e-posta adresi girin
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Şifre</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error>
                        Şifrenizi giriniz
                    </mat-error>
                </mat-form-field>

                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="center center">
                    <a class="forgot-password" [routerLink]="'/auth/forgot-password'">
                        Şifrenizi unuttunuz ?
                    </a>
                </div>

                <button mat-raised-button color="accent" class="submit-button" aria-label="LOGIN"
                        [disabled]="loginForm.invalid">
                    Giriş
                </button>

            </form>
<!-- 
          
            <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Don't have an account?</span>
                <a class="link" [routerLink]="'/pages/auth/register-2'">Create an account</a>
            </div> -->
        </div>
    </div>
</div>