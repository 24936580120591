// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false
};
export const onSite = {
    supportedLanguages: 'en,tr,fr,de,es,it,pt', //,
    supportedLanguagesER: 'en,tr,fr,de,es,it,pt',//,fr,de,es,it,pt
    onPremisePrefix: 'KM',
    backgroundImage:'dark-material-bg.jpg?y=45',
    onSiteLogo: 'LogoCom.svg',
    onSiteLogoEN: 'LogoComEN.png',
    logoloader: 'km_logo.png'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
