import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { sessionStore } from '../app.module';
import { BaseService } from './helpers/base.service';
import { CookieService } from 'ngx-cookie-service';
import { Shift } from 'app/main/pages/profile/tabs/user-shift/shift.model';
import { ShiftModel } from 'app/models/data.model';


@Injectable()
export class PsychologistService extends BaseService {

  // Observable navItem source
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this._authNavStatusSource.asObservable();


  private _dataShiftSource = new BehaviorSubject<ShiftModel>([] as any);
  dataShiftSource$ = this._dataShiftSource.asObservable();

  constructor(private http: HttpClient, private _router: Router, cookieService: CookieService) {
    super(http, cookieService, _router);
  }

  MyProfile() {
    return this.basePost(this.baseUrl + '/account/profile', null)
      .pipe(map(res => {
        if (res != null) {
          return res;
        }
        return false;
      }));
  }
  UserProfile(rowId) {
    return this.basePost(this.baseUrl + '/account/profile/' + rowId, null)
      .pipe(map(res => {
        if (res != null) {
          return res;
        }
        return false;
      }));
  }
  List() {

    return this.basePost(this.baseUrl + '/psychologist/list', null)
      .pipe(map(res => {
        if (res != null) {
          return res;
        }
        return false;
      }));
  }
  AreaList() {
    return this.basePost(this.baseUrl + '/psychologist/areas', null)
      .pipe(map(res => {
        if (res != null) {
          return res;
        }
        return false;
      }));
  }

  
  AddUpdate(data) {

    return this.basePost(this.baseUrl + '/psychologist/addupdate', data)
      .pipe(map(res => {
        if (res != null) {
          return res;
        }
        return false;
      }));
  }
  Delete(rowId) {

    return this.basePost(this.baseUrl + '/psychologist/delete/' + rowId, null)
      .pipe(map(res => {
        if (res != null) {
          return res;
        }
        return false;
      }));
  }
  AddShift(data: Shift) {
    return this.basePost(this.baseUrl + '/psychologist/shift/add', data)
      .pipe(map(res => {
        if (res != null) {
          return res;
        }
        return false;
      }));
  }
  RemoveShift(data) {
    return this.basePost(this.baseUrl + '/psychologist/shift/remove', data)
      .pipe(map(res => {
        if (res != null) {
          return res;
        }
        return false;
      }));
  }
  ListShift(rowId) {
    return this.basePost(this.baseUrl + '/psychologist/shift/list/' + rowId, null)
      .pipe(map(res => {
        if (res != null) {
          return res;
        }
        return false;
      }));
  }
  TotalShift() {
    return this.basePost(this.baseUrl + '/psychologist/shift/total/', null)
      .pipe(map(res => {
        if (res != null) {
          return res;
        }
        return false;
      }));
  }
  TotalShiftJson(): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.basePost(this.baseUrl + '/psychologist/shift/total/json', null)
       .subscribe((res: any) => {
           this._dataShiftSource.next(res.responseData);
            resolve(this.dataShiftSource$);
        }, reject);
    });
  }
  DeptShiftJson(dept): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.basePost(this.baseUrl + '/psychologist/shift/' + dept + '/json', null)
       .subscribe((res: any) => {
           this._dataShiftSource.next(res.responseData);
            resolve(this.dataShiftSource$);
        }, reject);
    });
  }
  AgentTotalShiftJson(rowId): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.basePost(this.baseUrl + '/psychologist/shift/'+ rowId +'/total/json', null)
       .subscribe((res: any) => {
           this._dataShiftSource.next(res.responseData);
            resolve(this.dataShiftSource$);
        }, reject);
    });
  }
  AgentDeptShiftJson(rowId,dept): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.basePost(this.baseUrl + '/psychologist/shift/'+ rowId +'/'+ dept +'/json', null)
       .subscribe((res: any) => {
           this._dataShiftSource.next(res.responseData);
            resolve(this.dataShiftSource$);
        }, reject);
    });
  } 
 
}