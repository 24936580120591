import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { storageFactory } from './services/helpers/storageFactory';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import 'hammerjs';

import { DateAdapter } from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';  
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoginService } from './services/account/login.service';
import { UserService } from './services/account/user.service';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './services/helpers/authguard';
import { BaseService } from './services/helpers/base.service';
import { InterceptorService } from './services/helpers/interceptor.service';
import { PsychologistService } from './services/psychologist.services';
import { DashboardService } from './services/dashboard.services';
import { ShiftChartComponent } from './layout/components/shift-chart/shift-chart.component';
import { ClientService } from './services/client.services';
import { ForgotPasswordModule } from './main/forgot-password/forgot-password.module';
import { ResetPasswordModule } from './main/reset-password/reset-password.module';

const appRoutes: Routes = [
 
     { path: '', pathMatch: 'full', redirectTo: '/pages/login' },
     {
        path        : 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    }
];


export const localStore = storageFactory(localStorage);
export const sessionStore = storageFactory(sessionStorage);


@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        AppRoutingModule,
        // App modules
        LayoutModule,
        SampleModule,
        ForgotPasswordModule,
        ResetPasswordModule
    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
        LoginService,
        PsychologistService,
        ClientService,
        DashboardService,
        AuthGuard,
        UserService, 
        BaseService, {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        },
          { // TimeZone için eklenmişti, gerek kalmadı.
            provide: MAT_DATE_LOCALE,
            useValue: 'tr-TR'
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MAT_MOMENT_DATE_FORMATS
        }
     ] 
})
export class AppModule
{
}
