import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { sessionStore } from "../app.module";
import { BaseService } from "./helpers/base.service";
import { CookieService } from "ngx-cookie-service";
import { Shift } from "app/main/pages/profile/tabs/user-shift/shift.model";
import { ShiftModel } from "app/models/data.model";

@Injectable()
export class ClientService extends BaseService {
    // Observable navItem source
    private _authNavStatusSource = new BehaviorSubject<boolean>(false);
    // Observable navItem stream
    authNavStatus$ = this._authNavStatusSource.asObservable();

    private _dataShiftSource = new BehaviorSubject<ShiftModel>([] as any);
    dataShiftSource$ = this._dataShiftSource.asObservable();

    constructor(
        private http: HttpClient,
        private _router: Router,
        cookieService: CookieService
    ) {
        super(http, cookieService, _router);
    }

    List() {
        return this.basePost(this.baseUrl + "/clients/list", null).pipe(
            map((res) => {
                if (res != null) {
                    return res;
                }
                return false;
            })
        );
    }

    AddUpdate(data) {
        return this.basePost(this.baseUrl + "/client/addupdate", data).pipe(
            map((res) => {
                if (res != null) {
                    return res;
                }
                return false;
            })
        );
    }

    Delete(rowId) {
        return this.basePost(
            this.baseUrl + "/client/delete/" + rowId,
            null
        ).pipe(
            map((res) => {
                if (res != null) {
                    return res;
                }
                return false;
            })
        );
    }

    Read(rowId) {
        return this.basePost(this.baseUrl + "/client/read/" + rowId, null).pipe(
            map((res) => {
                if (res != null) {
                    return res;
                }
                return false;
            })
        );
    }

    AddUpdateCompanyContact(data) {
        return this.basePost(
            this.baseUrl + "/client/contact/addupdate",
            data
        ).pipe(
            map((res) => {
                if (res != null) {
                    return res;
                }
                return false;
            })
        );
    }

    DeleteCompanyContact(rowId) {
        return this.basePost(
            this.baseUrl + "/client/contact/delete/" + rowId,
            null
        ).pipe(
            map((res) => {
                if (res != null) {
                    return res;
                }
                return false;
            })
        );
    }

    ListContact(rowId) {
        return this.basePost(
            this.baseUrl + "/clients/contact/list/" + rowId,
            null
        ).pipe(
            map((res) => {
                if (res != null) {
                    return res;
                }
                return false;
            })
        );
    }

    AddUpdateCompanyEmployee(data) {
        return this.basePost(
            this.baseUrl + "/client/employee/addupdate",
            data
        ).pipe(
            map((res) => {
                if (res != null) {
                    return res;
                }
                return false;
            })
        );
    }

    DeleteCompanyEmployee(rowId, companyId) {
        return this.basePost(
            this.baseUrl + "/client/employee/delete/" + companyId + "/" + rowId,
            null
        ).pipe(
            map((res) => {
                if (res != null) {
                    return res;
                }
                return false;
            })
        );
    }

    DeleteCompanyEmployees(companyRowId, rowIds) {
        return this.basePost(this.baseUrl + "/client/employees/delete", {
            companyRowId: companyRowId,
            rowId: rowIds,
        }).pipe(
            map((res) => {
                if (res != null) {
                    return res;
                }
                return false;
            })
        );
    }

    ListEmployee(rowId) {
        return this.basePost(
            this.baseUrl + "/clients/employee/list/" + rowId,
            null
        ).pipe(
            map((res) => {
                if (res != null) {
                    return res;
                }
                return false;
            })
        );
    }

    SuccessFullCalls() {
        return this.basePost(this.baseUrl + "/calls/successfull", null).pipe(
            map((res) => {
                if (res != null) {
                    return res;
                }
                return false;
            })
        );
    }

    UpdateConvesationNotification(data) {
        return this.basePost(
            this.baseUrl + "/calls/update/notification",
            data
        ).pipe(
            map((res) => {
                if (res != null) {
                    return res;
                }
                return false;
            })
        );
    }

    getConversationReport() {
        return this.basePost(this.baseUrl + "/api/generateReport", null).pipe(
            map((res) => {
                if (res != null) {
                    return res;
                }
                return false;
            })
        );
    }
}
