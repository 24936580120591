import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { sessionStore } from '../app.module';
import { BaseService } from './helpers/base.service';
import { CookieService } from 'ngx-cookie-service';
import { Shift } from 'app/main/pages/profile/tabs/user-shift/shift.model';


@Injectable()
export class DashboardService extends BaseService {
 
  // Observable navItem source
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this._authNavStatusSource.asObservable();

  constructor(private http: HttpClient, private _router: Router, cookieService: CookieService) {
    super(http,cookieService, _router);
  }
  
  DashboardInfo() {
    return this.basePost(this.baseUrl + '/dashboard/info',null)
      .pipe(map(res => {
        if(res!=null){
          return res;
        }
        return false;
      }));
  } 
  }