import { Observable, Subscription } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router/';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders
} from '@angular/common/http';
import { tap } from "rxjs/operators";
import { CookieService } from 'ngx-cookie-service';
import { BaseService } from './base.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
    private isUpdating = false;
    private jwtHelper: JwtHelperService = new JwtHelperService();
    private offsetSeconds = 3600;
    private subscriptions = new Array<Subscription>();
    private interKey ="";

    private subscription5: Subscription;

    constructor(
        private injector: Injector, private _cookieService: CookieService,private _baseService: BaseService,
        private router: Router
    ) {
    //    this.subscription5 = this._baseService.bearer$.subscribe((value) => {
    //         this.interKey = value;
    //     });
     }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token =  this._cookieService.get('auth_token');
        if (request.url.indexOf("upload") > 0 || request.url.indexOf("support/reports") > 0) {
            request = request.clone({
                setHeaders: {
                    Authorization: token != null ? 'Bearer ' + token : '',
                    'accept-language': this._cookieService.get('lang')
                }
            });
        } else {
            request = request.clone({
                setHeaders: {
                    Authorization: token != null ? 'Bearer ' + token : '',
                    'Content-Type': 'application/json',
                    'accept-language': this._cookieService.get('lang')
                }
            });
        }


        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {

                        if (this._cookieService.get('session_id') != null && !request.url.includes('refreshtoken') && !request.url.includes('elogin')) { // my api endpoint to avoid to trigger the interceptor when I refresh the token.
                            try {
                                const expirationLeft = this.jwtHelper.getTokenExpirationDate(token).getTime() - Date.now(); //in ms
                                //    console.log(expirationLeft);
                                if (expirationLeft <= 30000 && !this.isUpdating) {
                                    //     alert("expired");//     this.updateAuthToken();
                                }
                            } catch (e) { }
                        }
                    }
                },
                (error: any) => {
                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 401) {
                            //  localStore.clear();
                            for (const sub of this.subscriptions) {
                                sub.unsubscribe();
                            }
                            this._cookieService.delete("auth_token", "/");
                            this._cookieService.delete("session_id", "/");
                            this._cookieService.delete("tax_number", "/");
                            this._cookieService.delete("user_info", "/");
                            // this.router.navigateByUrl('/auth/login');

                            // console.log('interceptor redirected', this.router.url);
                            if (!this.router.url || this.router.url.indexOf('/pages/login') != 0)
                                if (this.router.url && this.router.url.length > 3 && this.router.url.indexOf('pages/login') == -1)
                                    this.router.navigateByUrl("/pages/login?returnUrl=" + this.router.url);
                                else
                                    this.router.navigateByUrl("/pages/login");
                        } else {
                            return error.error;
                        }
                    }
                }
            ));
    }
 
}