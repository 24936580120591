import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class BaseService {

    baseUrl: string = 'http://api.sento.com.tr';
    //baseUrl: string = 'https://localhost:5001';
    domainUrl: string = 'http://panel.sento.com.tr';//
    //domainUrl: string = 'https://localhost:5001';//



    public _bearer = new BehaviorSubject<any>({} as any);
    bearer$ = this._bearer.asObservable();
    public _key: string = "";

    constructor(protected _http: HttpClient,
        private _cookieService: CookieService, private router: Router) {
        this.loggedIn_.next(!!this.getBaseItem('auth_token'));
        this.setAuthHeaders();
        this.bearer$.subscribe((value) => {
            this._key = value;
        });
    }

    public baseSack = {
        user_info: {},
        session_id: "",
        auth_token: "",
        current_letter: "",
        tax_number: ""
    };

    public taxNumber: string = "";
    public currentToken: string;

    private loggedIn_ = new BehaviorSubject<boolean>(false);
    loggedIn = this.loggedIn_.asObservable();

    protected headers: HttpHeaders = new HttpHeaders();
    public authToken: string = "";
    protected handleError(error: any) {
        if (error.headers != undefined) {
            var applicationError = error.headers.get('Application-Error');
            // either applicationError in header or model error in body
            if (applicationError) {
                return Observable.throw(applicationError);
            }
        }

        if (error.stack != undefined) {
            console.log(error);
            return Observable.throw(error);
        } else {
            var modelStateErrors: string = '';
            var serverError = error.json();

            if (!serverError.type) {
                for (var key in serverError) {
                    if (serverError[key])
                        modelStateErrors += serverError[key] + '\n';
                }
            }

            modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
            return Observable.throw(modelStateErrors || 'Server error');
        }

    }
    setBearer(bear) {
        this._bearer.next(bear);
    }
    getBearer() {
        return this._key;
    }
    setAuthHeaders() {
        this.headers = new HttpHeaders();
        this.authToken = this.getBaseItem('auth_token');
        this.taxNumber = this.getBaseItem('tax_number');

        this.headers.append('Language', 'EN');
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Authorization', `Bearer ${this._key}`);
    }

    changeLoggedIn(loggedIn) {
        this.loggedIn_.next(loggedIn);
        this.setAuthHeaders();
    }

    public getSessionId() {
        return this.getBaseItem('session_id');
    }
    public getTaxNumber() {
        return this.getBaseItem('tax_number');
    }
    public clearCookie() {

        this._cookieService.delete("auth_token", "/");
        this._cookieService.delete("session_id", "/");
        this._cookieService.delete("tax_number", "/");
        this._cookieService.delete("user_info", "/");
    }

    setBaseItem(key, value) {

        this._cookieService.set(key, value, 120, "/");
    }
    getBaseItem(key) {
        return this._cookieService.get(key)
    }

    getAuthToken() {
        return this.currentToken;
    }
    getAuthTokenUpdate() {
        let headers = new HttpHeaders();
        headers.append('Language', 'EN');
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

        return this._http
            .post(
                this.baseUrl + '/' + this.getBaseItem('session_id') + '/' + this.getBaseItem('tax_number') + '/account/refreshtoken',
                {}, { headers }
            )
            .pipe(map(res => {
                let j = JSON.parse(res.toString());
                this.currentToken = j['auth_token'];

                this.setBaseItem('auth_token', j['auth_token']);
                this.setBaseItem('session_id', j['id']);
                this.setBaseItem('tax_number', res['tax_number']);

                this.changeLoggedIn(true);
                return this.currentToken;
            }, (error) => {
                this.router.navigate(['/auth/500']);
            }));

    }

    basePost(url, data) {
        let headers = new HttpHeaders();
        headers.append('Language', 'EN');
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');

        return this._http
            .post(url, data, { headers })
            .pipe(map(res => {
                return res as any;
            }, (error) => {
                this.handleError(error);
            }));
    }

    timeFormatterForPost(date) {
        return date;
    }

}