
import { FormControl } from '@angular/forms';

export interface ValidationResult {
    hasNumber: boolean;
    hasUpper: boolean;
    hasLower: boolean;
    hasSimilar: boolean;
    hasSpecial: boolean;
    hasLength: boolean;
    hasError: boolean;
    minLength:number;
    minSpecialChar:number;
}

export class PasswordValidator {

    public static insane(control: FormControl): ValidationResult {
        let hasNumber = /\d/.test(control.value);
        let hasUpper = /[A-Z]/.test(control.value);
        let hasLower = /[a-z]/.test(control.value);
        let hasSimilar = /[l1oO0iL]/.test(control.value);
        let hasSpecial = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/].{1,}/.test(control.value);
        // console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
        const valid = hasNumber && hasUpper && hasLower && hasSpecial  && (control.value.length>19);
        if (!valid) {
            // return what´s not valid
            return { 
                hasNumber:hasNumber,
                hasUpper:hasUpper,
                hasLower:hasLower,
                hasSimilar:false,
                hasSpecial:hasSpecial,
                hasLength:control.value.length>19,
                hasError:!valid,
                minLength:20,
                minSpecialChar:2
                };
        }
        return null;
    }

    public static strong(control: FormControl): ValidationResult {
        let hasNumber = /\d/.test(control.value);
        let hasUpper = /[A-Z]/.test(control.value);
        let hasLower = /[a-z]/.test(control.value);
        let hasSimilar = /[l1oO0iL]/.test(control.value);
        let hasSpecial = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(control.value);
        // console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
        const valid = hasNumber && hasUpper && hasLower && hasSpecial && (control.value.length>7);
        if (!valid) {
            // return what´s not valid
            return { 
                hasNumber:hasNumber,
                hasUpper:hasUpper,
                hasLower:hasLower,
                hasSimilar:false,
                hasSpecial:hasSpecial,
                hasLength:control.value.length>7,
                hasError:!valid,
                minLength:8,
                minSpecialChar:1
                };
        }
        return null;
    }

    public static medium(control: FormControl): ValidationResult {
        let hasNumber = /\d/.test(control.value);
        let hasUpper = /[A-Z]/.test(control.value);
        let hasLower = /[a-z]/.test(control.value);
        let hasSimilar = /[l1oO0iL]/.test(control.value);
        let hasSpecial = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/].{1,}/.test(control.value);
        // console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
        const valid = hasNumber && hasUpper && hasLower  && (control.value.length>5);
        if (!valid) {
            // return what´s not valid
            return { 
                hasNumber:hasNumber,
                hasUpper:hasUpper,
                hasLower:hasLower,
                hasSimilar:false,
                hasSpecial:true,
                hasLength:control.value.length>5,
                hasError:!valid,
                minLength:6,
                minSpecialChar:1
                };
        }
        return null;
    }

    public static low(control: FormControl): ValidationResult {
        let hasNumber = /\d/.test(control.value);
        let hasUpper = /[A-Z]/.test(control.value);
        let hasLower = /[a-z]/.test(control.value);
        let hasSimilar = /[l1oO0iL]/.test(control.value);
        let hasSpecial = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/].{1,}/.test(control.value);
        // console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
        const valid = hasNumber && hasUpper && hasLower  && (control.value.length>4);
        if (!valid) {
            // return what´s not valid
            return { 
                hasNumber:true,
                hasUpper:hasUpper,
                hasLower:hasLower,
                hasSimilar:false,
                hasSpecial:true,
                hasLength:control.value.length>4,
                hasError:!valid,
                minLength:5,
                minSpecialChar:1
                };
        }
        return null;
    }
}