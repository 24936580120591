import { NgModule } from '@angular/core';
import { ShiftChartComponent } from './shift-chart.component';
 

@NgModule({
    declarations: [
        ShiftChartComponent
    ],
    exports: [
        ShiftChartComponent
    ],
})
export class ShiftChartModule
{
}
