import { Component, OnInit } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable, Subscription } from 'rxjs';
import { DataModel, ShiftModel } from 'app/models/data.model';
import { HttpClient } from '@angular/common/http';
import { PsychologistService } from 'app/services/psychologist.services';
import { Shift } from '../pages/profile/tabs/user-shift/shift.model';

@Component({
    selector: 'sample',
    templateUrl: './sample.component.html',
    styleUrls: ['./sample.component.scss']
})
export class SampleComponent  implements OnInit {
    data: Observable<DataModel>;
    dataShift:  Observable<ShiftModel>;
 
    shiftJson:ShiftModel[] = [];
    private userSubscription: Subscription;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private http: HttpClient,
        private _psyService: PsychologistService,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
       // this.data = this.http.get<DataModel>('../assets/data.json');
 

    }  
    ngOnInit(): void {
        this._psyService.TotalShiftJson().then(p=>{
            this.dataShift=p;
        });
    } 
}
