export const locale = {
    lang: 'tr',
    data: {
        'NAV': {
            'APPLICATIONS': 'Raporlar',
            'SAMPLE'        : {
                'TITLE': 'Dashboard',
                'BADGE': '15'
            },
        },
    }
};
