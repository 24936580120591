<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="start" [style.background-image]="'url(' + imageName + ')'" [style.background-size]="'cover'" >

   <div id="login-intro" fxFlex fxHide fxShow.gt-xs> 
     <div class="logo" [@animate]="{value:'*',params:{scale:'0.1'}}" >
           <img src="assets/images/logos/sento-logo.png">
           <div style="text-align: center;" class="secondary-text mb-8 ml-16" style="font-size: 12px;" >yeni nesil kurumsal psikolojik danışmanlık</div>
       </div>  
   </div>

        <div id="reset-form-wrapper" fusePerfectScrollbar *fuseIfOnDom 
        [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}" fxLayout="column">

              <div id="login-form">

                <div class="logo" fxHide.gt-xs>
                    <img src="assets/images/logos/sento-logo.png">
                </div>
                
                
                <div class="title"> Yeni Şifre Oluştur</div>
    
                <form [formGroup]="form" name="form" *ngIf="!linkInvalid" (ngSubmit)="onSubmit(form.value)" class="text-left mt-4">
    
                    <mat-form-field>
                        <input matInput formControlName="password" class="form-control validation-field"  required placeholder="Şifre" type="password">
                     
                         <!-- <mat-error *ngIf="resetPasswordFormErrors.passwordConfirm.required">
                            {{translate('Şifre doğrulama gereklidir')}}
                         </mat-error> -->
                       
                         
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput  formControlName="passwordConfirm" class="form-control validation-field" placeholder="Şifrenizi Tekrar Girin" type="password">
                        <mat-error *ngIf="form.get('passwordConfirm').hasError('mismatchedPasswords')">
                            {{translate('İki şifre birbiri ile aynı olmalı')}}
                        </mat-error>  
                    </mat-form-field>
                           
                    <mat-error *ngIf="resetPasswordFormErrors.password.hasError&&!resetPasswordFormErrors.password.hasNumber">
                        {{translate('En az 1 adet rakam kullanmalısınız')}}
                     </mat-error>
                     <mat-error *ngIf="resetPasswordFormErrors.password.hasError&&!resetPasswordFormErrors.password.hasUpper">
                        {{translate('En az 1 adet büyük harf gereklidir')}}
                     </mat-error>
                     <mat-error  *ngIf="resetPasswordFormErrors.password.hasError&&!resetPasswordFormErrors.password.hasLower">
                        {{translate('En az 1 adet küçük harf gereklidir')}}
                     </mat-error>
                     <mat-error  *ngIf="resetPasswordFormErrors.password.hasError&&resetPasswordFormErrors.password.hasSimilar">
                        {{translate('l 1 o O 0 i L gibi benzer karakter kullanılmamalıdır')}}
                     </mat-error>
                     <mat-error  *ngIf="resetPasswordFormErrors.password.hasError&&!resetPasswordFormErrors.password.hasSpecial">
                        {{translate('-!$%^&* gibi en az')}} {{resetPasswordFormErrors.password.minSpecialChar}}  {{translate('adet özel karakterler gereklidir')}}
                     </mat-error>
                     <mat-error  *ngIf="resetPasswordFormErrors.password.hasError&&!resetPasswordFormErrors.password.hasLength">
                        {{translate('Şifreniz en az')}} {{resetPasswordFormErrors.password.minLength}} {{translate('karakter olmalıdır')}}
                     </mat-error>
                    <button mat-raised-button class="submit-button" color="accent" [disabled]="form.invalid">
                            Şifrenizi Oluşturun
                    </button>
    
                </form>
    
                <div *ngIf="linkInvalid" class="message-box error mt-20 mb-20" >
                  Geçersiz Bağlantı.
                </div>
                <div class="login" fxLayout="row" fxLayoutAlign="center center">
                    <a class="link" [routerLink]="'/pages/login'">Giriş sayfasına geri dön</a>
                </div>
    
            </div>
        </div>
    </div>