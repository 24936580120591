import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
//import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'app/services/account/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'app/services/account/user.service';
import { Credentials } from 'app/models/credentials.interface';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslatePipe } from '@ngx-translate/core';

import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

declare var crispAgentCall: Function;

@Component({
    selector: 'login-2',
    templateUrl: './login-2.component.html',
    styleUrls: ['./login-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [TranslatePipe],
    animations: fuseAnimations
})

export class LoginComponent implements OnInit {
    private subscription: Subscription;
    brandNew: boolean;
    errors: string;
    isRequesting: boolean;
    submitted: boolean = false;
    onPremisePrefix: string;
    onSiteLogo: string;
    onSiteBackground: SafeHtml;
    loggedIn: boolean = false;
    loginForm: FormGroup;
    loginFormErrors: any;
    companyList: any[];
    isExecutive: boolean = false;

    clickedTable: boolean = false;
    savedEmail: string;
    savedPass: string;
    imageName:string = "/assets/images/backgrounds/blank.jpg";
    background:any = {
        message:"a path to peace of mind"
    };

    loadingInfo: string;
    returnUrl: string = "";

    credentials: Credentials = { taxnumber: '', email: '', password: '' };

    constructor(private fuseConfig: FuseConfigService,
        private _translatePipe: TranslatePipe,
        private titleService: Title,
        private _sanitizer: DomSanitizer,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private formBuilder: FormBuilder,
         private activatedRoute: ActivatedRoute, 
         private router: Router, 
         public snackBar: MatSnackBar,
          private loginService: LoginService, private userService: UserService, private _fuseSplashScreenService: FuseSplashScreenService) {

        this.fuseConfig.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.loginFormErrors = {
            email: {},
            password: {},
            taxnumber: {}
        };
        // this.onPremisePrefix = onSite.onPremisePrefix;
        // this.onSiteLogo = onSite.onSiteLogo;
        // this.onSiteBackground = onSite.backgroundImage;
        this.titleService.setTitle("Sento - Panel");
                 
    }

    getBackgroundrUrl() {
        this.loginService.wallpaper()
        .subscribe(
            result => {
                this.background = result;
                this.imageName = "./assets/images/backgrounds/" +result.imgUrl;
            });
        
            
        // background: url('/assets/images/backgrounds/dark-material-bg.jpg') no-repeat;
        // background-size: cover;
        
        // snip snip -> fetch the url from somewhere
        // const profilePicUrl = "/assets/images/backgrounds/" + onSite.backgroundImage;
        // const style = `url(${profilePicUrl})`;
        // return this._sanitizer.bypassSecurityTrustStyle(style);
    }

    // getSantizeUrl("/assets/images/backgrounds/${onSiteBackground}")
    public getSantizeUrl(url: string) {
        return this._sanitizer.bypassSecurityTrustUrl(url);
    }
    ngOnInit() {
        this.getBackgroundrUrl();
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            taxnumber: ['', Validators.minLength(10)]
        });
 
        this.loginForm.valueChanges.subscribe(() => {
            this.onLoginFormValuesChanged();
        });

        this.subscription = this.activatedRoute.queryParams.subscribe(
            (param: any) => {
                this.brandNew = param['brandNew'];
                this.credentials.email = param['email'];
                this.returnUrl = param['returnUrl'];
            });
    }
 

    preLogin({ value, valid }: { value: Credentials, valid: boolean }) {
        this.submitted = true;
        this.isRequesting = true;
        this.errors = '';
        if (valid) {
            this.loginService.preLogin(value.email, value.password)
                .subscribe(
                    result => {
                        this.isRequesting = false;
                        if(!result){
                            alert("E-posta / Şifreyi kontrol ediniz");
                            return;
                        }
                        if (result == true) {

                         this._fuseSplashScreenService.show();
                            //    this.userService.getUserCompanyRelations("").subscribe((value) => {
                            //     this.companyList = value;
                            //   })
                            this.router.navigate(['/dashboard']);
                            this.userService.userInfoSubscription().subscribe(val => {
                              
                                setTimeout(() => {
                                    this._fuseSplashScreenService.hide();
                                }, 3000);
                            });
                        }
                        else if (result.isExecutive != undefined) {
                            this.savedEmail = value.email;
                            this.savedPass = value.password;

                            this.loggedIn = true;

                            this.isExecutive = result.isExecutive;
                            this.companyList = result.companies;
                        }
                        else {
                            this.snackBar.open(this._translatePipe.transform("LOGINPAGE.INVALIDLOGIN"), 'OK', {
                                verticalPosition: 'top',
                                duration: 10000
                            });
                        }
                    },
                    error => this.errors = error);
        }
    }

    resetLogin() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            taxnumber: ['']
        });
        this.companyList = [];
        this.savedEmail = this.savedEmail;
        this.savedPass = "";

        this.loggedIn = false;

    }

    onLoginFormValuesChanged() {
        for (const field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }
}
